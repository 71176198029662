import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import contentParser from "gatsby-wpgraphql-inline-images";

import parse from "html-react-parser";

import { inlineUrls } from "../utils";

import Layout from "../layouts/home";
import SEO from "../components/SEO";

import Social from "../components/Social/sharer";
import WorkWithUs from "../components/Forms/workwithus";

class SingleCaseStudy extends React.Component {
  renderPreviousLink = () => {
    const {
      pageContext: { prev },
    } = this.props;

    if (!prev) {
      return null;
    }
    return (
      <Link
        to={`/portfolio/${prev.uri}`}
        className="pagination-previous"
        title={prev.title}
      >
        <span className="icon-left-open-mini"></span>
      </Link>
    );
  };

  renderNextLink = () => {
    const {
      pageContext: { next },
    } = this.props;

    if (!next) {
      return null;
    }
    return (
      <Link
        to={`/portfolio/${next.uri}`}
        className="pagination-next"
        title={next.title}
      >
        <span className="icon-right-open-mini"></span>
      </Link>
    );
  };
  render() {
    const defaultPost = {
      post: {
        featuredImage: {
          imageFile: {
            publicURL: "/images/team/placeholder.jpg",
          },
        },
      },
    };

    const {
      location,
      data: {
        wordPress: {
          casestudy: {
            title,
            excerpt,
            content,
            featuredImage,
            acf_brand_colors: { backgroundColor, textColor },
            acf_case_study_images: { visuals, videos },
            acf_result: { result },
            acf_client_testimonial: { testimonial },
          },
        },
      },
    } = this.props;

    // console.log(this.props.data.wordPress.casestudy.acf_brand_colors);

    return (
      <Layout location={location} cssClass="casestudy">
        <SEO title={title} description={excerpt} />
        <section id="header" className="text-center">
          <div className="featured">
            {featuredImage ? (
              <Img
                className="bg"
                fluid={featuredImage.imageFile.childImageSharp.fluid}
                alt="image"
                fadeIn={false}
              />
            ) : (
              <img
                className="bg"
                src={defaultPost.post.featuredImage.imageFile.publicURL}
                alt={parse(title)}
              />
            )}
            <h1>{parse(title)}</h1>
          </div>
        </section>
        <section id="excerpt" className="bg-light-gray p-top p-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 lead line text-center">
                {parse(excerpt)}
              </div>
            </div>
          </div>
        </section>
        <Social location={location} />
        <section
          id="body"
          className="b-top b-bottom mt-0 relative"
          style={{ backgroundColor: backgroundColor, color: textColor }}
        >
          <div className="container-full">
            <div className="row">
              <div className="col-md-6">
                <div className="img-overview">
                  {featuredImage && featuredImage.imageFile && (
                    <Img
                      className="bg"
                      alt={featuredImage.altText}
                      fluid={featuredImage.imageFile.childImageSharp.fluid}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 p-top p-bottom p-left p-right">
                {contentParser({ content }, inlineUrls)}
              </div>
            </div>
          </div>
        </section>
        {result && (
          <section id="result" className="bg-light-gray p-top p-bottom mt-0">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2>Result</h2>
                </div>
                <div className="col-md-6 nl2br">
                  {contentParser({ content: result }, inlineUrls)}
                </div>
              </div>
            </div>
          </section>
        )}
        <section id="visuals" className="bg-light-gray p-bottom mt-0">
          <div className="container-full">
            <div className="row no-gutters">
              {visuals &&
                visuals.map((item, index) => {
                  // console.log(item.image);
                  return (
                    <div key={index} className={`image ${item.size}`}>
                      {item.image && item.image.imageFile.childImageSharp ? (
                        <Img
                          alt={item.image.altText}
                          fluid={item.image.imageFile.childImageSharp.fluid}
                        />
                      ) : (
                        <img
                          src={item.image.imageFile.publicURL}
                          alt={item.image.altText}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        {videos && (
          <section className="bg-light-gray p-top">
            {videos.map((item, index) => {
              // console.log(item.image);
              return (
                <div
                  className="row justify-content-center p-bottom"
                  key={index}
                >
                  <div>
                    <iframe
                      name={`video${item.vimeoId}`}
                      title={`video${item.vimeoId}`}
                      className="video"
                      style={{ border: 0 }}
                      src={`https://player.vimeo.com/video/${item.vimeoId}?title=0&amp;byline=0&amp;portrait=0&amp;color=304cd1&amp;loop=0`}
                      width="800"
                      height="450"
                    ></iframe>
                  </div>
                </div>
              );
            })}
          </section>
        )}

        {testimonial && (
          <section id="testimonial" className="bg-light-gray p-bottom mt-0">
            <div className="container">
              <div className="row">
                <div className="offset-md-3 col-md-6 text-center nl2br">
                  <h3>Client testimonial</h3>
                  {testimonial}
                </div>
              </div>
            </div>
          </section>
        )}
        <section id="pagination" className="bg-light-gray p-bottom mt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 pagination">
                <ul>
                  <li>{this.renderPreviousLink()}</li>
                  <li>{this.renderNextLink()}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <WorkWithUs />
      </Layout>
    );
  }
}
export default SingleCaseStudy;

export const singleCaseStudy = graphql`
  query GET_caseStudy($id: ID!) {
    wordPress {
      casestudy(id: $id) {
        title
        acf_subtitle {
          subtitle
        }
        excerpt
        content
        featuredImage {
          ...CaseStudyImage
        }
        acf_brand_colors {
          backgroundColor
          textColor
        }
        acf_case_study_images {
          overviewImage {
            ...CaseStudyImage
          }
          visuals {
            size
            image {
              ...CaseStudyImage
            }
          }
          videos {
            vimeoId
          }
        }
        acf_result {
          result
        }
        acf_client_testimonial {
          testimonial
        }
      }
    }
  }
`;

export const CaseStudyImage = graphql`
  fragment CaseStudyImage on WordPress_MediaItem {
    altText
    sourceUrl
    content
    mediaItemId
    modified
    mimeType
    imageFile {
      extension
      publicURL
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
